var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table2",
          attrs: {
            id: "meeting",
            title: "LBLLISTMEETING",
            columns: _vm.grid2.columns,
            gridHeight: _vm.grid2.height,
            data: _vm.assessPlan.conferences,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            isFullScreen: false,
            editable: _vm.editable && !_vm.disabled,
            selection: "multiple",
            rowKey: "ramAssessmentConferenceId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "LBLADD",
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addConference },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.assessPlan.conferences &&
                  _vm.assessPlan.conferences.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          disabled: _vm.disabled,
                          label: "LBLREMOVE",
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.deleteConference },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.assessPlan.conferences &&
                  _vm.assessPlan.conferences.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          disabled: _vm.disabled,
                          isSubmit: _vm.isSaveConference,
                          url: _vm.saveConferenceUrl,
                          param: _vm.assessPlan.conferences,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveAssessConference,
                          btnCallback: _vm.saveConferenceCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table3",
          staticClass: "q-mt-sm",
          attrs: {
            id: "docu",
            title: "LBL0000618",
            columns: _vm.grid3.columns,
            gridHeight: _vm.grid3.height,
            data: _vm.assessPlan.relatedDocuments,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            isFullScreen: false,
            editable: _vm.editable && !_vm.disabled,
            selection: "multiple",
            rowKey: "ramRelatedDocumentId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBL0000619", icon: "add" },
                        on: { btnClicked: _vm.addEtc },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addRelateDocument },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.assessPlan.relatedDocuments &&
                  _vm.assessPlan.relatedDocuments.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          disabled: _vm.disabled,
                          label: "LBLREMOVE",
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.deleteRelateDocument },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.assessPlan.relatedDocuments &&
                  _vm.assessPlan.relatedDocuments.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          disabled: _vm.disabled,
                          isSubmit: _vm.isSaveRelateDocument,
                          url: _vm.saveRelateDocumentUrl,
                          param: _vm.assessPlan.relatedDocuments,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveRelateDocument,
                          btnCallback: _vm.saveRelateDocumentCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "neartable",
          staticClass: "q-mt-sm",
          attrs: {
            id: "near",
            title: "LBL0001117",
            columns: _vm.gridNear.columns,
            data: _vm.gridNear.data,
            gridHeight: _vm.gridNear.height,
            filtering: false,
            columnSetting: false,
            isFullScreen: false,
            usePaging: false,
            expandAll: true,
            selection: "multiple",
            rowKey: "iimNearAccidentId",
          },
          on: { linkClick: _vm.nearLinkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addNearTask },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editable &&
                          _vm.gridNear.data &&
                          _vm.gridNear.data.length > 0,
                        expression:
                          "editable && gridNear.data && gridNear.data.length>0",
                      },
                    ],
                    attrs: { label: "LBLREMOVE", icon: "remove" },
                    on: { btnClicked: _vm.deleteNearTask },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "patroltable",
          staticClass: "q-mt-sm pb-3",
          attrs: {
            id: "patrol",
            title: "LBL0001118",
            columns: _vm.gridPatrol.columns,
            data: _vm.gridPatrol.data,
            gridHeight: _vm.gridPatrol.height,
            filtering: false,
            columnSetting: false,
            isFullScreen: false,
            usePaging: false,
            expandAll: true,
            selection: "multiple",
            rowKey: "saiPatrolId",
          },
          on: { linkClick: _vm.patrolLinkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addPatrolTask },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  _vm.gridPatrol.data &&
                  _vm.gridPatrol.data.length > 0
                    ? _c("c-btn", {
                        attrs: { label: "LBLREMOVE", icon: "remove" },
                        on: { btnClicked: _vm.deletePatrolTask },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }